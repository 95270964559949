 /* eslint-disable */ 
 import React from 'react'
 import { navigate } from 'gatsby-link'
 import Layout from '../components/Layout'
 import logo from '../img/EYlogo.png'
 import Recaptcha from "react-google-recaptcha";

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY;

const recaptchaRef = React.createRef();

 function encode(data) {
   return Object.keys(data)
     .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
     .join('&')
 }
 
 export default class Index extends React.Component {
   constructor(props) {
     super(props)
     this.state = { isValidated: false }
   }
 
   handleChange = (e) => {
     this.setState({ [e.target.name]: e.target.value })
   }

   handleRecaptcha = value => {
    this.setState({ "g-recaptcha-response": value });
    const recaptchaValue = recaptchaRef.current.getValue();
    this.props.onSubmit(recaptchaValue);
    
  };
 
  // test this
   handleSubmit = (e) => {
     e.preventDefault()
     const form = e.target
     const airkey = process.env.GATSBY_AIRTABLE_API
     const fields = {"fields": {"name": this.state.name, "email": this.state.email, "message": this.state.message}}
     fetch("https://api.airtable.com/v0/appYHJj6beFGTBCSO/July2020", {
       method: "POST",
       headers: {"Authorization": `Bearer ${airkey}`,
                 "Content-Type": "application/json"},
       body: JSON.stringify(fields)
     })   
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
      e.preventDefault()
   }
   

 
   render() {
     return (
       <Layout>
         <section className="section">
         <br/>
           <div className="container">
           <img src={logo} alt="EY Labs Support" style={{}} />
             <div className="content">
               <h1>Contact Us</h1>
               <form
                 name="contact"
                 method="post"
                 action="/contact/thanks/"
                 data-netlify-recaptcha="true"
                 onSubmit={this.handleSubmit}
               >
                 <noscript>
                  <p>This form won’t work with Javascript disabled</p>
                </noscript>
                 {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                 <input type="hidden" name="form-name" value="contact" />
                 <div className="field">
                   <label className="label" htmlFor={'name'}>
                     Your name
                   </label>
                   <div className="control">
                     <input
                       className="input"
                       type={'text'}
                       name={'name'}
                       onChange={this.handleChange}
                       id={'name'}
                       required={true}
                     />
                   </div>
                 </div>
                 <div className="field">
                   <label className="label" htmlFor={'email'}>
                     Email
                   </label>
                   <div className="control">
                     <input
                       className="input"
                       type={'email'}
                       name={'email'}
                       onChange={this.handleChange}
                       id={'email'}
                       required={true}
                     />
                   </div>
                 </div>
                 <div className="field">
                   <label className="label" htmlFor={'message'}>
                     Message
                   </label>
                   <div className="control">
                     <textarea
                       className="textarea"
                       name={'message'}
                       onChange={this.handleChange}
                       id={'message'}
                       required={true}
                     />
                   </div>
                 </div>
                 <div className="field">
                  <Recaptcha
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_KEY}
                    onChange={this.handleRecaptcha}
                  />
                 </div>
                 <div className="field">
                   <button className="button is-link" type="submit">
                     Send
                   </button>
                 </div>
               </form>
             </div>
           </div>
         </section>
       </Layout>
     )
   }
 }
 